import * as SentryReact from "@sentry/react";
import { env } from "../../env";

export const Sentry = SentryReact;

Sentry.init({
  dsn: "https://2bb6ac80101230698e96e571607f0407@o4507498045440000.ingest.de.sentry.io/4507498994729040",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
    }),
  ],

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [env.nestApiUrl],

  // Session Replay
  replaysSessionSampleRate: 0,
  // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,

  enabled: process.env.NODE_ENV === "production", // not using vite mode because we want to use sentry in staging etc. as well

  environment: import.meta.env.MODE,
});
