import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { Callout, Flex } from "@radix-ui/themes";

export const UnexpectedError: React.FC = () => {
  return (
    <Flex align="center" justify="center" p="5">
      <Callout.Root color="red">
        <Callout.Icon>
          <ExclamationTriangleIcon />
        </Callout.Icon>
        <Callout.Text>
          <p>Ups, es ist ein unerwarteter Fehler aufgetreten 😱</p>
          <br />
          <p>Bitte laden Sie die Seite neu.</p>
        </Callout.Text>
      </Callout.Root>
    </Flex>
  );
};
