import { Flex, Heading } from "@chakra-ui/react";
import { useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useAppLogo from "../assets/use-app-icon.png";
import { AuthenticationContext } from "../authentication/authentication.context";
import { StationLoginComponent } from "./components/station-login.component";
import { StationOpenComponent } from "./components/station-open.component";

export function UnlockStationPageComponent() {
  const { stationId } = useParams();
  const [params] = useSearchParams();
  const authenticationContext = useContext(AuthenticationContext);
  const token = params.get("token") ?? authenticationContext.token;

  return (
    <Flex gap="48px" direction="column" align="center">
      <Flex gap="32px" direction="column" align="center">
        <div>
          <img src={useAppLogo} className="shared-logo" alt="Use logo" />
        </div>
        <Heading as="h1">Fahrradstation</Heading>
      </Flex>
      <Flex direction="column" gap="32px" w="320px" maxW="100%">
        {token ? (
          <StationOpenComponent stationId={stationId ?? ""} token={token} />
        ) : (
          <StationLoginComponent stationId={stationId ?? ""} />
        )}
      </Flex>
    </Flex>
  );
}
