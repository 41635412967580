import { ErrorBoundary } from "@sentry/react";
import { PropsWithChildren } from "react";
import { UnexpectedError } from "./shared/components/unexpected-error";
import { useTrackPageView } from "./shared/utils/posthog";

export const MainRoot: React.FC<PropsWithChildren> = ({ children }) => {
  useTrackPageView();

  return (
    <ErrorBoundary fallback={<UnexpectedError />}>{children}</ErrorBoundary>
  );
};
